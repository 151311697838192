const Data = [
    {
        id:"1",
        name:"Muffins",
        imgsrc:"products/muffins.jpg",
    },
    {
        id:"2",
        name:"Cheesecakes",
        imgsrc:"products/cheesecake.jpg",
    },
    {
        id:"3",
        name:"Cakes",
        imgsrc:"products/cake.jpg",
    },
    {
        id:"4",
        name:"Cookies",
        imgsrc:"products/cookie.jpg",
    },
    {
        id:"5",
        name:"Rolls",
        imgsrc:"products/roll.jpg",
    },
    {
        id:"6",
        name:"Macaroons",
        imgsrc:"products/macaroon.jpg",
    },

]

export default Data;