import React from "react";

const Header = () => {
    return (
        <div className='container'>
            <h1 className="Head"><i class="fa fa-birthday-cake"></i> Jericalla desserts</h1>
            <h4 className="Head2"></h4>
        </div>
    )
}

export default Header;