import React from 'react';
import './App.css';
import Header from './Components/Header';
import ShowCase from './Components/ShowCase';
import Data from './Data';

const App = ()=> {
  return (
    <>
    <Header />

    <div className='cakeDisplay'>

      {Data.map((val) => {
        return (
          <ShowCase key={val.id} name={val.name} imgsrc={val.imgsrc} />
        );
      })
      }

    </div>
  </>
  );
}

export default App;